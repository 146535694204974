// 登录接口
import {
	axios
} from '../utils/http.js'

const userApi = {
	Adminlogin: '/Login_Admin_verification',
	Userlogin: '/Login_verification',
	logout: '/api/logout'
}

//管理员登陆
export function Adminlogin(data) {
	return axios({
		url: userApi.Adminlogin,
		method: 'post',
		data:data
	})
}

//普通用户登陆
export function Userlogin(data) {
	return axios({
		url: userApi.Userlogin,
		method: 'post',
		data:data
	})
}


//退出登陆
export function logout(data) {
	return axios({
		url: userApi.logout,
		method: 'GET',
		data: data
	})
}
