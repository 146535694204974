<template>
	<div class="Login">
		<div class="Login_BJ">
			<div class="Login1">
				<div class="Login_Title">
					<h4 style="color:#409EFF;letter-spacing:5px;">冷链验证报告生成系统</h4>
					<p style="font-size: 14px;color: #ccc;margin-top: 10px;">Cold chain verification report generation
						system</p>
				</div>
				<div class="Login_Conter">
					<el-input prefix-icon='el-icon-user' @keyup.enter.native="Login" type="text"
						class="Login_Conter_input" v-model="loginForm.bzd_username" placeholder="请输入登录账号" clearable>
					</el-input>
					<el-input prefix-icon='el-icon-unlock' @keyup.enter.native="Login" type="password"
						class="Login_Conter_input" v-model="loginForm.bzd_password" placeholder="请输入登录密码" show-password>
					</el-input>
					<el-select v-model="value" placeholder="请选择登录身份" class="Login_Conter_input">
						<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
					<div class="Login_Conter_new">
						<el-checkbox v-model="checkeds">记住密码</el-checkbox>
						<router-link class="Login_Conter_new_a" to="/">注册账号</router-link>
					</div>
					<el-button type="primary" :loading="loading" style="width: 100%;" @click="Login()">立即登录</el-button>
				</div>
			</div>
			<div class="Login2">
				<img src="../../assets/img/loginyy.png" style="width: 100%;height: auto;">
			</div>
		</div>
	</div>
</template>

<script>
	import {
		Adminlogin,Userlogin
	} from '../../api/login.js'
	export default {
		data() {
			return {
				options: [{
					value: '1',
					label: '管理员登录'
				}, {
					value: '2',
					label: '用户登录'
				}],
				value: '',
				loginForm: {
					bzd_username: '',
					bzd_password: '',
				},
				checkeds: true,
				loading: false
			}
		},
		onLoad() {
			this.checkeds = this.$store.state.LoginInfo.checkeds
		},
		mounted() {
			this.loginForm.bzd_username = this.$store.state.LoginInfo.bzd_username,
				this.loginForm.bzd_password = this.$store.state.LoginInfo.bzd_password
			this.$store.commit('Login', {
				token: '',
				username: this.loginForm.bzd_username,
				password: this.loginForm.bzd_password,
				checkeds: this.checked
			})
		},
		methods: {
			Login() {
				var _this = this
				if (this.loginForm.bzd_username === undefined) {
					this.$message({
						message: '请检查你输入的账号是否为空',
						type: 'error'
					});
					return false
				} else if (this.loginForm.bzd_password === undefined) {
					this.$message({
						message: '请检查你输入的密码是否为空',
						type: 'error'
					});
					return false
				}else if(this.value===''){
					this.$message({
						message: '请选择你的登录身份',
						type: 'error'
					});
					return false
				} else {
					this.loading = true
					let Parame = {
						bzd_username: this.loginForm.bzd_username,
						bzd_password: this.$md5(this.loginForm.bzd_password)
					}
					if(_this.value==1){
						Adminlogin(Parame).then(res => {
							if (res.data.Code == 200) {
								this.$store.commit('permiss',_this.value)
								if (_this.checkeds) {
									this.$store.commit('Login', {
										token: res.data.Data,
										username: this.loginForm.bzd_username,
										password: this.loginForm.bzd_password,
										checked: this.checkeds
									})
								} else {
									this.$store.commit('Login', {
										token: res.data.Data,
										username: '',
										password: '',
										checked: this.checkeds
									})
								}
								this.$router.push({
									path: this.$route.query.redirect || '/index'
								})
							}
							this.loading = false
						})
					}else if(_this.value==2){
						Userlogin(Parame).then(res => {
							if (res.data.Code == 200) {
								this.$store.commit('permiss',_this.value)
								if (_this.checkeds) {
									this.$store.commit('Login', {
										token: res.data.Data,
										username: this.loginForm.bzd_username,
										password: this.loginForm.bzd_password,
										checked: this.checkeds
									})
								} else {
									this.$store.commit('Login', {
										token: res.data.Data,
										username: '',
										password: '',
										checked: this.checkeds
									})
								}
								this.$router.push({
									path: this.$route.query.redirect || '/index'
								})
							}
							this.loading = false
						})
					}
				}
			}
		}
	}
</script>

<style>
	.Login {
		height: 100%;
		background-image: url(../../assets/img/LoginBJ.jpg);
		background-size: cover auto;
		background-position: center right;
	}

	.Login_BJ {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.Login1 {
		background: #fff;
		height: 320px;
		width: 400px;
		padding: 40px;
		border-radius: 5px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		box-shadow: 5px 5px 10px -10px #000;
		z-index: 2;
	}

	.Login2 {
		z-index: 1;
		margin-top: -25px;
		max-width: 500px;
	}

	.Login_Title {
		text-align: center;
		font-size: 25px;
		padding: 15px 0;
	}

	.Login_Conter {
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.Login_Conter_txt {
		margin-bottom: 50px;
		font-size: 20px;
	}

	.Login_Conter_input {
		margin-bottom: 20px;
	}

	.Login_Conter_new {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 20px;
	}

	.Login_Conter_new_a {
		color: #ccc;
		font-size: 14px;
		text-decoration: none;
	}
</style>
